.business_dashboard{
    h2 {
        line-height: 35px;
        font-size: 22px;
    }
    .text-green{
        color: #61d7c7;
    }
    .chart_div{
        width:100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
    .leadImg{
      opacity: 0.8;
    }
    .leadValue{
      position: absolute;
      border-radius: 50px;
      background-color: #fff;
      border:none;
      width: 17%;
      height: 17%;
      padding: 12px;
      align-items: center;
      display: flex;
      justify-content: center;
    }
    .leadValue_left{
      left: 12%;
      top: 46%;
    }
    .leadValue_right{
      right: 12%;
      top: 46%;
    }
    .leadValue_bottom{
      top: 67%;
      left: 41%;
      width: 20%;
      height: 20%;
      font-size: 1.25rem;
      background-color: #f9b739;
      color: #524a86;
      border: 3px solid #524a86;
      font-weight: 600;
    }
    .top_txt{
      position: absolute;
      bottom: 58px;
      left: 30px;
      font-size: 12px;
      padding: 5px;
      font-weight: 600;
    }
    .lagger_top_txt{
      bottom: 55px;
      left: 41px;
    }
}