//
// Root
//

// Light mode
@include color-mode(light) {
    // Page bg
    --#{$prefix}page-bg: #{$page-bg};

    // App blank bg color
    --#{$prefix}app-blank-bg: #{$app-blank-bg-color};

    // Header
    --#{$prefix}header-bg-color: #{get($header-config, bg-color)};
    --#{$prefix}header-border-bottom: #{get($header-config, border-bottom)};
    --#{$prefix}header-box-shadow: #{get($header-config, box-shadow)};

    // Footer
    --#{$prefix}footer-bg-color: #{get($footer-config, bg-color)};
}

// Dark mode
@include color-mode(dark) {
    // Page bg
    --#{$prefix}page-bg: #{$page-bg-dark};

    // App blank bg color
    --#{$prefix}app-blank-bg: #{$app-blank-bg-color-dark};

    // Header
    --#{$prefix}header-bg-color: #{get($header-config, bg-color-dark)};
    --#{$prefix}header-border-bottom: #{get($header-config, border-bottom-dark)};
    --#{$prefix}header-box-shadow: #{get($header-config, box-shadow-dark)};

    // Footer
    --#{$prefix}footer-bg-color: #{get($footer-config, bg-color-dark)};
}
